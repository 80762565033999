// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MediaSlide_OT\\+3y{display:block;height:100%;position:relative;transform:translateZ(0);width:100%}.MediaSlide_OT\\+3y._shadows_twr6X:after{background:linear-gradient(180deg,#1110 53.5%,#11111185),linear-gradient(180deg,#111,#1110 21%),linear-gradient(180deg,#11111152,#1110 18%),linear-gradient(114deg,#0000001f 64%,#0000001f 90%);bottom:0;content:\"\";left:0;position:absolute;right:0;top:0}.MediaSlide_OT\\+3y._contain_Zmbxi>img.image_h66GK,.MediaSlide_OT\\+3y._contain_Zmbxi>video.image_h66GK{object-fit:contain}img.image_h66GK,video.image_h66GK{display:block;height:100%;object-fit:cover;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"MediaSlide": "MediaSlide_OT+3y",
	"_shadows": "_shadows_twr6X",
	"_contain": "_contain_Zmbxi",
	"image": "image_h66GK"
};
module.exports = ___CSS_LOADER_EXPORT___;
